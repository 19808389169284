import React, { useEffect, useState } from 'react';
import { setupLiff } from '../lib/liff';

export default function Home() {
  const [, setMessage] = useState({});
  useEffect(() => {
    setupLiff()
      .then((res) => {
        setMessage(res);
      })
      .catch((err) => {
        setMessage(err);
      });
  }, []);
  return <div></div>;
}
